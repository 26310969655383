<template>
	<div class="container">
		<div class="page-sub-box ewm">
			<div class="locker_setting_list sub_new_style01 sub_ui_box1">
				<div class="page_search_box line_bottom_1px pb-0 mb-0">
					<div class="flex space-x-2 inner">
						<div class="mt-2">
							기준년월
						</div>
						<DxDateBox
							:edit-enabled="false"
							:styling-mode="stylingMode"
							width="100"
							v-model="searchType.customTypes.dayStart"
							type="date"
							display-format="yyyy.MM"
							dateSerializationFormat="yyyyMMdd"
							date-out-of-range-message="시작일은 종료일보다 작거나 같아야 합니다."
							:max="searchType.customTypes.dayEnd"
							maxLength="7"
							invalid-date-message="입력 데이터가 유효하지 않습니다."
							:calendar-options="{
								zoomLevel: 'year',
								minZoomLevel: 'year',
								maxZoomLevel: 'year',
							}"
							@value-changed="onDayStartChanged"
						>
							<DxValidator validation-group="validationSearch">
								<DxRequiredRule message="기준년월은 필수입니다." />
							</DxValidator>
						</DxDateBox>
						<div class="mt-1">
							~
						</div>
						<DxDateBox
							:styling-mode="stylingMode"
							width="100"
							v-model="searchType.customTypes.dayEnd"
							type="date"
							display-format="yyyy.MM"
							dateSerializationFormat="yyyyMMdd"
							dateOutOfRangeMessage="종료일은 시작일보다 크거나 같아야 합니다."
							maxLength="7"
							:min="searchType.customTypes.dayStart"
							invalid-date-message="입력 데이터가 유효하지 않습니다."
							:calendar-options="{
								zoomLevel: 'year',
								minZoomLevel: 'year',
								maxZoomLevel: 'year',
							}"
							@value-changed="onDayEndChanged"
						>
							<DxValidator validation-group="validationSearch">
								<DxRequiredRule message="기준년월은 필수입니다." />
							</DxValidator>
						</DxDateBox>
						<div class="mt-2">
							성명
						</div>
						<DxButton
							:styling-mode="stylingMode"
							:text="searchType.customTypes.targetName"
							:width="120"
							:height="30"
							class="btn_XS white light_filled"
							@click="onOpen"
						>
						</DxButton>
						<DxTextBox
							:styling-mode="textStylingMode"
							v-model="searchType.customTypes.targetId"
							:width="120"
							:height="30"
							:read-only="true"
							class="mar_ri10"
						>
						</DxTextBox>
						<DxButton text="검색" class="btn_M box-btn-search" type="button" :height="30" @click="selectDataList" />
					</div>
				</div>
			</div>

			<div class="h-2/5">
				<div class="sub_title_txt my-4 flex">
					<h2>시험정보</h2>
					<div class="flex justify-end flex-grow">
						<div class="total-count-item">
							검색결과 <span class="tet-calr1">{{ reportGridData.totalCount }}</span> 개
						</div>
					</div>
				</div>

				<DxDataGrid
					class="grid-box"
					ref="reportGridData"
					:data-source="reportGridData.dataSource"
					:allow-column-resizing="true"
					:column-resizing-mode="'nextColumn'"
					:show-borders="false"
					:show-column-headers="true"
					:show-column-lines="true"
					:show-row-lines="true"
					:row-alternation-enabled="false"
					:hover-state-enabled="true"
					:word-wrap-enabled="true"
					:no-data-text="this.$_msgContents('CMN_NO_DATA')"
					@row-click="onClickRow"
					width="100%"
					height="300"
				>
					<DxFilterRow :visible="true" />
					<DxScrolling mode="virtual" />
					<DxSelection mode="single" />
					<DxHeaderFilter :visible="true" />

					<DxColumn
						caption="시험구분"
						alignment="center"
						data-field="examSheetType"
						width="120"
						:allow-sorting="false"
						:allowHeaderFiltering="true"
						:allowFiltering="true"
					>
						<DxLookup :data-source="codes.examSheetType.dataSource" value-expr="value" display-expr="label" />
					</DxColumn>
					<DxColumn
						caption="시험계획"
						alignment="center"
						data-field="scheNm"
						:allow-sorting="false"
						:allowHeaderFiltering="false"
						:allowFiltering="true"
						:calculate-display-value="rowData => `[${rowData.scheYmd}] ${rowData.scheNm}`"
					/>
					<DxColumn
						caption="시험지"
						data-field="examNm"
						alignment="center"
						:allow-sorting="false"
						:allowHeaderFiltering="false"
						:allowFiltering="false"
					/>
					<DxColumn
						caption="점수"
						data-field="myScore"
						alignment="center"
						width="100"
						:allow-sorting="false"
						:allowHeaderFiltering="false"
						:allowFiltering="false"
						:calculate-display-value="rowData => `${rowData.myScore}/${rowData.totalScore}`"
					>
					</DxColumn>
					<DxColumn
						caption="순위"
						data-field="myRanking"
						alignment="center"
						width="100"
						:allow-sorting="false"
						:allowHeaderFiltering="false"
						:allowFiltering="false"
						:calculate-display-value="rowData => `${rowData.myRanking}/${rowData.totalRanking}`"
					>
					</DxColumn>
					<DxColumn caption="정답분포">
						<DxColumn
							caption="객관식"
							data-field="myChoiceScore"
							alignment="center"
							width="100"
							:allow-sorting="false"
							:allowHeaderFiltering="false"
							:allowFiltering="false"
							:calculate-display-value="rowData => `${rowData.myChoiceScore}/${rowData.totalChoiceScore}`"
						/>
						<DxColumn
							caption="O/X"
							data-field="myOxScore"
							alignment="center"
							width="100"
							:allow-sorting="false"
							:allowHeaderFiltering="false"
							:allowFiltering="false"
							:calculate-display-value="rowData => `${rowData.myOxScore}/${rowData.totalOxScore}`"
						/>
						<DxColumn
							caption="단답형"
							data-field="myShortScore"
							alignment="center"
							width="100"
							:allow-sorting="false"
							:allowHeaderFiltering="false"
							:allowFiltering="false"
							:calculate-display-value="rowData => `${rowData.myShortScore}/${rowData.totalShortScore}`"
						/>
						<DxColumn
							caption="서술형"
							data-field="myLongScore"
							alignment="center"
							width="100"
							:allow-sorting="false"
							:allowHeaderFiltering="false"
							:allowFiltering="false"
							:calculate-display-value="rowData => `${rowData.myLongScore}/${rowData.totalLongScore}`"
						/>
					</DxColumn>
				</DxDataGrid>
			</div>

			<div class="h-2/5">
				<div class="sub_title_txt my-4 flex">
					<h2>문항정보</h2>
					<div class="flex justify-end flex-grow">
						<div class="total-count-item">
							검색결과 <span class="tet-calr1">{{ reportDetailGridData.totalCount }}</span> 개
						</div>
					</div>
				</div>
				<DxDataGrid
					class="grid-box"
					ref="reportDetailGridData"
					:data-source="reportDetailGridData.dataSource"
					:allow-column-resizing="true"
					:column-resizing-mode="'nextColumn'"
					:show-borders="false"
					:show-column-headers="true"
					:show-column-lines="true"
					:show-row-lines="true"
					:row-alternation-enabled="false"
					:hover-state-enabled="true"
					:word-wrap-enabled="true"
					:no-data-text="this.$_msgContents('CMN_NO_DATA')"
					width="100%"
					height="300"
				>
					<DxFilterRow :visible="true" />
					<DxHeaderFilter :visible="true" />
					<DxScrolling mode="virtual" />

					<DxColumn
						caption="문항번호"
						alignment="center"
						width="80"
						data-field="questionOrd"
						:allow-sorting="false"
						:allowHeaderFiltering="false"
						:allowFiltering="false"
					/>
					<DxColumn
						caption="문항내용"
						alignment="center"
						data-field="qustionNm"
						:allow-sorting="false"
						:allowHeaderFiltering="false"
						:allowFiltering="true"
						cell-template="qustionNmTemplate"
					/>
					<DxColumn
						caption="문항 정답율"
						data-field="answerRate"
						alignment="center"
						width="100"
						:allow-sorting="false"
						:allowHeaderFiltering="false"
						:allowFiltering="false"
						:calculate-display-value="rowData => `${rowData.answerRate}%`"
					/>
					<DxColumn
						caption="배점"
						data-field="scroe"
						alignment="center"
						width="100"
						:allow-sorting="false"
						:allowHeaderFiltering="false"
						:allowFiltering="false"
					>
					</DxColumn>
					<DxColumn
						caption="정답유형"
						data-field="answerTypeCd"
						alignment="center"
						width="120"
						:allow-sorting="false"
						:allowHeaderFiltering="true"
						:allowFiltering="true"
					>
						<DxLookup :data-source="codes.answerTypeCd.dataSource" value-expr="codeId" display-expr="codeNm" />
					</DxColumn>
					<DxColumn
						caption="정답유무"
						data-field="isAnswer"
						alignment="center"
						width="120"
						:allow-sorting="false"
						:allowHeaderFiltering="true"
						:allowFiltering="false"
					>
						<DxLookup :data-source="codes.isAnswer.dataSource" value-expr="codeId" display-expr="codeNm" />
					</DxColumn>
					<DxColumn
						caption="입력/선택 답안"
						data-field="targetAnswers"
						alignment="center"
						width="150"
						:allow-sorting="false"
						:allowHeaderFiltering="false"
						:allowFiltering="false"
					>
					</DxColumn>
					<DxColumn
						caption="모범 답안"
						data-field="answers"
						alignment="center"
						width="150"
						:allow-sorting="false"
						:allowHeaderFiltering="false"
						:allowFiltering="false"
					>
					</DxColumn>

					<template #qustionNmTemplate="{ data }">
						<div v-html="data.data.qustionNm"></div>
					</template>
				</DxDataGrid>
			</div>

			<!-- Agent Modal -->
			<modal-add-agent
				:isOpen="agent.popupVisible"
				:showModalTitle="true"
				:selectedIdList="agent.selectedIdList"
				@closeModal="onClose(false)"
				@saveModal="onSave"
				:minimumSelectionLength="agent.minimumSelectionLength"
				:maximumSelectionLength="agent.maximumSelectionLength"
				:saveBtnTxt="agent.saveBtnTxt"
			/>
			<!-- Agent Modal -->
		</div>
	</div>
</template>

<script>
import { DxTextBox } from 'devextreme-vue/text-box';
import { DxButton } from 'devextreme-vue/button';
import { DxDateBox } from 'devextreme-vue/date-box';
import {
	DxDataGrid,
	DxColumn,
	DxSelection,
	DxFilterRow,
	DxHeaderFilter,
	DxLookup,
	DxLoadPanel,
	DxScrolling,
} from 'devextreme-vue/data-grid';
import { DxValidator, DxRequiredRule } from 'devextreme-vue/validator';
import validationEngine from 'devextreme/ui/validation_engine';
import { getPastFromToday, isSuccess } from '@/plugins/common-lib';
import ModalAddAgent from '@/components/ewm/hr/modal-add-agent.vue';

let vm = null;

export default {
	components: {
		DxTextBox,
		DxButton,
		DxDateBox,

		DxDataGrid,
		DxColumn,
		DxSelection,
		DxFilterRow,
		DxHeaderFilter,
		DxLookup,
		DxLoadPanel,
		DxScrolling,

		DxRequiredRule,
		DxValidator,

		ModalAddAgent,
	},
	props: {},
	watch: {},
	data() {
		return {
			textStylingMode: 'filled',
			stylingMode: 'outlined', //outlined, underlined, filled
			searchType: {
				obj: {},
				defaultObj: { id: 'ROOT', codeValue: 'ROOT', codeNm: '전체' },
				defaultValue: 'ROOT',
				customTypes: {
					dayStart: getPastFromToday(11, 'months'),
					dayEnd: getPastFromToday(0, 'days'),
					targetName: '',
					targetId: '',
				},
				paramsData: null,
			},
			codes: {
				examSheetType: {
					dataSource: [],
					displayExpr: 'label',
					valueExpr: 'value',
				},
				answerTypeCd: {
					dataSource: [],
					displayExpr: 'codeNm',
					valueExpr: 'codeId',
				},
				isAnswer: {
					dataSource: [
						{ codeId: 0, codeNm: '정답' },
						{ codeId: 1, codeNm: '부분정답' },
						{ codeId: 2, codeNm: '오답' },
					],
					displayExpr: 'codeNm',
					valueExpr: 'codeId',
				},
			},
			agent: {
				popupVisible: false,
				selectedIdList: [],
				targetSelectedIdList: [],
				minimumSelectionLength: 1,
				maximumSelectionLength: 1,
				saveBtnTxt: '선택',
			},
			reportGridData: {
				dataSource: null,
				selectedRowsData: [],
				selectedRowKeys: [],
				totalCount: 0,
			},
			reportDetailGridData: {
				dataSource: null,
				selectedRowsData: [],
				selectedRowKeys: [],
				totalCount: 0,
			},
		};
	},
	computed: {},
	methods: {
		arrayEquals(arr1, arr2) {
			arr1.sort();
			arr2.sort();
			if (arr1.length === 0 && arr2.length === 0) {
				return false;
			}
			return arr1.length === arr2.length && arr1.every((v, i) => v === arr2[i]);
		},
		onOpen() {
			console.log(this.agent.targetSelectedIdList);
			this.agent.popupVisible = true;
			this.agent.selectedIdList = this.agent.targetSelectedIdList;
		},
		onClose() {
			this.agent.popupVisible = false;
			this.agent.selectedIdList = [];
		},
		onSave(value) {
			console.log(value);
			this.searchType.customTypes.targetName = value[0].agtNm;
			this.searchType.customTypes.targetId = value[0].agtid;
			this.agent.targetSelectedIdList = value[0].agtid;
			console.log(this.agent.targetSelectedIdList);
			this.onClose();
		},
		async onClickRow(data) {
			const targetId = this.searchType.customTypes.targetId;
			const scheId = data.data.scheId;
			const payload = {
				actionname: 'EWM_EXAM_REPORT_SCHE_QUESTIONS',
				path: '/' + scheId + '/target/' + targetId + '/questions',
				loading: false,
				useErrorPopup: true,
			};
			const res = await vm.CALL_EWM_API(payload);
			if (isSuccess(res)) {
				res.data.data.forEach(d => {
					console.log('dddddddddddddddd', d);
					// 정답
					if (this.arrayEquals(d.targetAnswers, d.answers)) {
						d.isAnswer = 0;
					}
					// 부분정답
					else if (d.feedbackScore > 0) {
						d.isAnswer = 1;
					}
					// 오답
					else {
						d.isAnswer = 2;
					}
					//d.targetAnswers?.reverse();
					//d.answers?.reverse();
				});
				this.reportDetailGridData.totalCount = res.data.data.length;
				this.reportDetailGridData.dataSource = res.data.data;
			} else {
				return () => {
					throw 'Data Loading Error';
				};
			}
		},
		/** @description : 데이터 검색 메서드 */
		async selectDataList() {
			if (!validationEngine.validateGroup('validationSearch').isValid) {
				return;
			}

			if (!vm.searchType.customTypes.targetId) {
				this.$_Toast(this.$_msgContents('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
				return;
			}

			const payload = {
				actionname: 'EWM_EXAM_REPORT_CARD',
				data: {
					startDt: this.searchType.customTypes.dayStart,
					endDt: this.searchType.customTypes.dayEnd,
					targetId: this.searchType.customTypes.targetId,
				},
				loading: false,
				useErrorPopup: true,
			};
			// console.log('payload',payload)
			const res = await vm.CALL_EWM_API(payload);
			if (isSuccess(res)) {
				this.reportGridData.totalCount = res.data.data.length;
				this.reportGridData.dataSource = res.data.data;
			} else {
				return () => {
					throw 'Data Loading Error';
				};
			}
		},
		onDayStartChanged(e){
			const changed = e.value.slice(0, 6)+'01';
			this.searchType.customTypes.dayStart = changed;
		},
		onDayEndChanged(e){
			const changed = e.value.slice(0, 6)+'29';
			this.searchType.customTypes.dayEnd = changed;
		},
		/** @description : 코드 로드 */
		async initCodeMap() {
			this.codeMap = await this.$_getCodeMapList('root_edu_exam_type,root_ewm_edu_exam_answer_type');
		},
		/** @description : 라이프사이클 created 호출되는 메서드 */
		createdData() {
			vm = this;

			this.initCodeMap().then(() => {
				this.codes.examSheetType.dataSource = this.$_enums.ewm.examSheetType.values;

				this.codes.answerTypeCd.dataSource = this.$_fetchCodesByDepth(this.codeMap['root_ewm_edu_exam_answer_type'], 2);
			});

			// this.onChangeSearchDay();
			// this.selectDataList();
		},
		/** @description : 라이프사이클 mounted 호출되는 메서드 */
		mountedData() {},
	},
	created() {
		this.createdData();
	},
	mounted() {
		this.mountedData();
	},
};
</script>

<style scoped>
.sub_new_style01 .page_search_box .inner div {
	display: inline-block;
}
.sub_new_style01 .page_search_box .inner > div {
	vertical-align: middle;
	margin-right: 10px;
}
.page_search_box .inner .box-btn-search {
	margin-right: 15px;
}
</style>
